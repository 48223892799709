// extracted by mini-css-extract-plugin
export var author = "books-module--author--nPb9J";
export var book = "books-module--book--hNzRb";
export var booksList = "books-module--booksList--jhL66";
export var buyList = "books-module--buyList--GjDOv";
export var cover = "books-module--cover--XS64q";
export var details = "books-module--details--IdK5t";
export var listItem = "books-module--listItem--QGJQY";
export var more = "books-module--more--EaGXc";
export var read = "books-module--read--vnd1u";
export var root = "books-module--root--KlOiI";
export var test = "books-module--test--PsjZJ";